<template>
  <div></div>
</template>
<script>
import Mapbox from '@/mixins/map/mapbox'
import { mapGetters } from 'vuex'
import parcelle from '@/mixins/parcelle'

export default {
  mixins: [Mapbox, parcelle],
  computed: {
    ...mapGetters({
      map_style_loaded: 'map/map_style_loaded',
    }),
    parcelles_selected() {
      return [
        this.form.informations.current_parcelle,
        ...this.form.informations.parcelles_voisines,
      ].filter((parcelle) => parcelle)
    },
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    cadastre: {
      type: Object,
      required: true,
    },
    map: {
      required: true,
    },
  },
  data() {
    return {
      max_parcelles_aggregate: 20,
      id_set_interval_opacity_layer: null,
      old_current_parcelle: null,
      id_time_out: null,
    }
  },
  watch: {
    map_style_loaded(is_loaded) {
      if (is_loaded) {
        this.map.on('click', this.selectOrUnselectParcelle)
      }
    },
  },
  mounted() {
    if (this.map) {
      this.map.on('click', this.selectOrUnselectParcelle)
    }
  },
  methods: {
    propertieNumExist(feature) {
      return this.isExist(this.getNestedObject(feature, 'properties', 'numero'))
    },
    selectOrUnselectParcelle(e) {
      let features = this.map.queryRenderedFeatures(e.point, {
        layers: [this.layer_background_parcelle_autour],
      })
      if (this.propertieNumExist(features[0])) {
        var parcelle_selected = this.cadastre.parcelles_autour[features[0].id]

        let reference_cadastrale_selected = parcelle_selected.properties.id

        let ref_layer_parcelle_selected =
          this.source_layers_all_parcelles_selected[1] +
          '-' +
          reference_cadastrale_selected

        if (this.layerExist(ref_layer_parcelle_selected)) {
          this.setInfosParcelles(
            this.unSelectParcelle(
              parcelle_selected,
              reference_cadastrale_selected,
            ),
          )
        } else if (
          this.parcelles_selected.length < this.max_parcelles_aggregate &&
          !this.layerExist(ref_layer_parcelle_selected)
        ) {
          this.setInfosParcelles(
            this.selectParcelle(
              reference_cadastrale_selected,
              parcelle_selected,
            ),
          )
        } else if (
          this.parcelles_selected.length >= this.max_parcelles_aggregate
        ) {
          this.toast(
            'Validation',
            `vous avez le droit de sélectionner ${this.max_parcelles_aggregate} parcelles voisines maximum ! `,
            'warning',
          )
        }
        this.$emit('setBatimentsAndSuperficie')
      }
    },
    selectParcelle(reference_cadastrale_selected, parcelle_selected) {
      let options = this.copyObject(this.options_polygone)
      options.color = this.green_parcelle_selected
      this.addPolygonsToMap(
        parcelle_selected,
        this.concatSourceAndLayersWithRef(
          ...this.source_layers_all_parcelles_selected,
          reference_cadastrale_selected,
        ),
        options,
      )
      let own_new_parcelles = [...this.parcelles_selected, parcelle_selected]
      let new_current_parcelle =
        this.getParcellePrincipalThroughCenterOfAllParcelles(own_new_parcelles)

      let new_parcelles_voisines = own_new_parcelles.filter(
        (parcelle) =>
          parcelle.properties.id !== new_current_parcelle.properties.id,
      )
      return {
        current_parcelle: new_current_parcelle,
        parcelles_voisines: new_parcelles_voisines,
      }
    },
    unSelectParcelle(parcelle_selected, reference_cadastrale_selected) {
      this.removeSourceAndLayers(
        ...this.concatSourceAndLayersWithRef(
          ...this.source_layers_all_parcelles_selected,
          reference_cadastrale_selected,
        ),
      )
      let own_new_parcelles = this.parcelles_selected.filter(
        (parcelle) =>
          parcelle.properties.id !== parcelle_selected.properties.id,
      )
      let new_current_parcelle =
        this.getParcellePrincipalThroughCenterOfAllParcelles(own_new_parcelles)

      this.old_current_parcelle = new_current_parcelle
        ? new_current_parcelle
        : this.form.informations.current_parcelle

      let new_parcelles_voisines = new_current_parcelle
        ? this.parcelles_selected.filter(
            (parcelle) =>
              parcelle.properties.id !== parcelle_selected.properties.id &&
              parcelle.properties.id !== new_current_parcelle.properties.id,
          )
        : []

      return {
        current_parcelle: new_current_parcelle,
        parcelles_voisines: new_parcelles_voisines,
      }
    },

    setInfosParcelles(parcelles) {
      this.form.informations.parcelles_voisines = parcelles.parcelles_voisines
      this.form.informations.current_parcelle = parcelles.current_parcelle
      this.removeSourceAndLayers(...this.source_layers_all_parcelles_selected)

      clearTimeout(this.id_time_out)
      this.disableAnimateLayers()
      this.id_set_interval_opacity_layer = this.animateLayers(
        true,
        this.getLayersParcellesSelected(this.parcelles_selected),
      )
      this.id_time_out = setTimeout(this.disableAnimateLayers, 4000)
    },
    disableAnimateLayers() {
      this.id_set_interval_opacity_layer = this.animateLayers(
        false,
        this.getLayersParcellesSelected(this.parcelles_selected),
        this.id_set_interval_opacity_layer,
      )
    },
  },

  beforeDestroy() {
    this.$store.commit('map/MAP_STYLLE_LOADED', false)
    if (!this.form.informations.current_parcelle) {
      this.form.informations.current_parcelle = this.old_current_parcelle
      this.addParcellesSelected(
        [
          this.form.informations.current_parcelle,
          ...this.form.informations.parcelles_voisines,
        ],
        this.green_parcelle_selected,
      )
      this.$emit('setBatimentsAndSuperficie')
    }

    if (this.map) {
      this.disableAnimateLayers()
      this.map.off('click', this.selectOrUnselectParcelle)
    }
  },
}
</script>
